header.page-header {
  padding:40px 50px;
  @media(max-width:600px) { padding:30px 40px; }
  @media(max-width:450px) { padding:20px 30px; }
  a#logo {
    display: inline-block;
  }
  .nav-wrap {
    border-radius: 4px;
    position: fixed;
    top:64px;
    right:70px;
    display: flex;
    z-index: 5500;
    @media(max-width:600px) {
      right:50px;
    }
    @media(max-width:450px) {
      right:40px;
    }
    .btn {
      padding-top:9.5px;
      padding-bottom:9.5px;
      @media(max-width:450px) {
        display: none;
      }
    }
  }
}

/* Navigation Button */
#mobile-burger-btn {
  cursor: pointer;
  height: 36px;
  margin:0;
  text-align:center;
  transition: right 0.5s;
  width: 42px;
  z-index: 100;
  color:$yellow;
  background:#7E5F3E;
  i {
    font-size:24px;
    position: relative;
    top:6.5px;
    color:#fff;
    &:nth-child(2) {
      display:none;
    }
  }
}
