.inner-wrap {
  &.contactpage{
    h1{
      line-height: .35;
      font-size: 53px;
      @media(max-width:600px){
        font-size: 28px;
        margin-top: 30px;
      }
    }
    h2{
      margin-bottom: 15px;
      font-size: 50px;
      @media(max-width:600px){
        font-size: 28px;
      }
    }
}
  .row {
    .col {
      @media(max-width:940px){
        width: 100%;
      }
      p{
        margin-bottom: 20px;
        line-height: 20px;
        &:nth-child(3){
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
        }
      }
      a{
        color: #fff;
      }
      .row{

        strong{
          display: block;
          font-family:$standard-font-family;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          color: #CA9C64;
          margin:15px 0 0 0;
          width: 100%;
        }
        .value{
          display: block;
          font-family:$standard-font-family;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          color: #fff;
          width: 100%;
          line-height:1.35;
          @media(max-width:600px) { font-size:18px; }
          @media(max-width:450px) { font-size:16px; }
        }
      }

      &:nth-child(1){
        justify-content: center;
        align-self: center;
      }
      &:nth-child(2){
        text-align: left;
        padding-left: 80px;
        @media(max-width:940px){
          padding-left: 0;
        }
      }

    }
  }
}
