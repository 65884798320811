#mobile-nav-items {
    display: block;
    position: absolute;
    top: 0;
    right: -396px;
    width:396px;
    max-width:100vw;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 5000;
    text-align:left;
    transition: right 0.3s ease;
    background:#000000;
    padding:0;
    z-index: 5500;
    &.push {
      right:0;
    }
    #mobile-nav-inner-items {
      min-height:calc(100vh - 20px);
      padding:40px 48px;
      left:auto !important;
    }
    .top {
      margin-bottom:40px;
      .close-btn {
        position: absolute;
        top:40px;
        right:40px;
        cursor: pointer;
        i {
          color:#fff;
          font-size:28px;
        }

      }
    }
    .btm{
      position: absolute;
      bottom: 40px;
      @media(max-height:688px) { display: none;}
      @media(max-height:640px) { display: block; position: relative; bottom: 0;}
      p {
        margin:13px 0;
        line-height: 2;
        a {
          color:#fff;
          text-decoration: underline;
        }

      }
      .social-items {
        margin-bottom:10px;
        @media(max-height:717px) { display: none;}
        @media(max-height:640px) { display: block;}
        a {
          color:#fff;
          margin-right:15px;
          text-decoration: underline;
          i {
            font-size:24px;
          }
        }
      }
      .copyright {
        font-weight:300;
        font-size: 13px;
        color: rgba(255,255,255,0.55);
        letter-spacing: 0;
        @media(max-height:749px) { display: none;}
        @media(max-height:640px) { display: block;}
        a {
          text-decoration: underline;
          color: rgba(255,255,255,0.55);
        }
      }


    }
    ul {
      margin:0;
      li {
        color:#fff;
        font-weight:700;
        display: block;
        text-align:left;
        margin:0 0 20px;
        a {
          color:#fff;
          line-height: 1.25em;
          display: block;
          padding:0;
          cursor: pointer;
          font-size: 20px;
          border-bottom: 2px solid rgba(#CA9C64, 0.7);
          padding-bottom: 5px;
          margin-bottom: 5px;
          font-weight:700;
          text-transform: uppercase;
          &:hover {
            text-decoration: none;
            color:$gold;
          }
          &.active {

          }
          @media(max-width:900px) { font-size:21px; margin-bottom:4px; }
          @media(max-width:600px) { font-size:19px; margin-bottom:3px; }
          @media(max-width:450px) { font-size:17px; margin-bottom:2px; }
        }
        .menuSummary {
          font-size: 12px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 14px;
          text-shadow: 0 0 4px rgba(0,0,0,0.67);
          font-weight:300;
        }
      }
    }
    a.btn {
      display:block;
      width:100%;
      text-align:center;
    }

}
