.slideshow {
  .cycle-slideshow {
    position: relative;
    z-index: 100;
  }
  .slide {
    width:100%;
    position: relative;
    background-repeat:no-repeat;
    background-size:cover;
    background-position: center;
    .txt-wrap {
      display:flex;
      align-items:center;
      height:380px;
      @media(max-width:900px) { height:340px; }
      @media(max-width:600px) { height:300px; }
      @media(max-width:450px) { height:285px; }
      div {
        h3 {
          font-size: 21px;
          color: #555656;
          letter-spacing: 1.75px;
          font-style: normal;
          font-weight: 700;
          line-height: 1.35;
          @media(max-width:900px) { font-size:19px; }
          @media(max-width:600px) { font-size:17px; }
          @media(max-width:450px) { font-size:16px; }
        }
        h2 {
          font-size: 41px;
          color: #206788;
          letter-spacing: 3.42px;
          font-style: normal;
          font-weight: 900;
          line-height: 1.35;
          @media(max-width:900px) { font-size:37px; }
          @media(max-width:600px) { font-size:33px; }
          @media(max-width:450px) { font-size:29px; }
        }
        h4 {
          font-size: 61px;
          color: #F0C809;
          letter-spacing: 5.08px;
          font-style: normal;
          font-weight: 900;
          line-height: 1;
          margin-bottom: 10px;
          @media(max-width:900px) { font-size:49px; }
          @media(max-width:600px) { font-size:41px; }
          @media(max-width:450px) { font-size:36px; }
        }
        a.btn {
          background: #206788;
          border-radius: 2px;
          min-width:84px;
          text-align:center;
          font-size:11px;
          line-height: 1.35;
          padding:10px 15px;
        }
      }
    }
  }
}

.sub-banners-wrap {
  margin:45px auto 0;
  .slideshow {
    border-radius:4px;
    overflow: hidden;
    .slide {
      .inner-wrap {
        width:100%;
      }
      .txt-wrap {
        padding:0 40px;
        width:450px;
        height:313px;
        position: relative;
        z-index:3;
        @media(max-width:900px) { height:295px; }
        @media(max-width:600px) { height:275px; }
        @media(max-width:450px) { height:260px; }
        div {
          position: relative;
          z-index:10;
          h3 { 
            opacity: 0.72;
            font-size: 16px;
            color: #FFFFFF;
            letter-spacing: 1.33px;
          }
          h2 {
            font-size: 37px;
            color: #FFFFFF;
            @media(max-width:900px) { font-size:33px; }
            @media(max-width:600px) { font-size:29px; }
            @media(max-width:450px) { font-size:26px; }  
           }
          h4 { 
            font-size: 61px;
            color: #F0C809;
            letter-spacing: 5.08px;
            @media(max-width:900px) { font-size:50px; }
            @media(max-width:600px) { font-size:41px; }
            @media(max-width:450px) { font-size:34px; }  
          }
          a.btn {
            background: #F2D247;
            color: #327F9A;
            letter-spacing: 0.92px;
          }
        }
        &:after {
          position: absolute;
          top: -125px;
          left: -40px;
          content: " ";
          background: rgba(40, 115, 145, 0.82);
          transform: rotate(20deg);
          width: 515px;
          height: 550px;
          z-index: 1;
          @media(max-width:900px) { left:-80px; }
          @media(max-width:600px) { left:-110px; }
          @media(max-width:450px) { left:-130px; }
        }
        &:before {
          position: absolute;
          top:-25px;
          left:456px;
          content: " ";
          background: rgba(#F1CD2F, 0.82);
          transform: rotate(20deg);
          width: 45px;
          height: 550px;
          z-index: 3;
          @media(max-width:900px) { left:416px; }
          @media(max-width:600px) { left:386px; }
          @media(max-width:450px) { left:326px; }
        }
      }
    }
  }
}