footer {
  .btm {
    background:#2B2B2B;
    padding:25px 0;
    color:rgba(#FFFFFF, 0.65);
    line-height:2;
    font-size:12px;
    .inner-wrap {
      .row {
        margin:0;
        display:flex;
        flex-wrap:wrap;
        .col {
          @media(max-width:600px) {
            text-align:center;
            width:100%;
            &:nth-child(1) {
              margin-bottom:7.5px;
            }
          }
          &:nth-child(1) {
            flex-grow:1;
          }
          a {
            color:rgba(#FFFFFF, 0.65);
          }
          i {
            font-size:16px;
          }
        }
      }
    }
  }
}
