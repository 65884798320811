button {
  border:0;
}
.btn {
  /* Rectangle 4: */
  background:$gold;
  color: #FFFFFF;
  border-radius:0;
  border:0;
  cursor: pointer;
  display:inline-block;
  font-size: 13px;
  font-style: normal;
  font-weight:700;
  line-height:1.34em;
  padding:14.5px 15px;
  position:relative;
  text-transform:uppercase;
  &:active {
    top:1px;
  }
  &:hover, &:focus {
    text-decoration: none;
    color: #FFFFFF;
  }
}

.back-btn {
  display:inline-block;
}

i.more-info-ico {
  display: inline-block;
  margin-left:5px;
  width:20px;
  height:20px;
  border-radius:100%;
  border:1px #fff solid;
  text-align:center;
  line-height: 18px;
  padding-left:1px;
}

.btn-txt {
  font-size: 16px;
  color: #698C9D;
  line-height: 22px;
  font-weight:600;
  display:inline-block;
  margin: 0 20px;
  border-bottom:2px solid #698C9D;
  &:hover {
    text-decoration: none;
  }
}


.tag {
  background: #75B50F;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  padding: 0 23px 0 10px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  position: absolute;
  left:10px;
  top:10px;
  color:#ffff;
  z-index: 1000;
  font-family:$title-font-family;
  font-size: 10px;
  color: #FFFFFF;
  letter-spacing: 0;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  pointer-events: none;
  &::before {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: '';
    height: 6px;
    right: 10px;
    position: absolute;
    width: 6px;
    top: 8px;
  }
  
  &::after {
    background: #fff;
    border-bottom: 12px solid transparent;
    border-left: 10px solid #75B50F;
    border-top: 12px solid transparent;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
  }
}