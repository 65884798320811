.listQuestions {
  margin-bottom: 30px;
  ol {
    li {
      font-style: normal;
      font-weight: 400;
      margin: 0;
      display: flex;
      span {
        display: inline-block;
        padding-right:10px;
        width:20px;
      }
      a {
        display: inline-block;
        background:$red;
      }
    }
  }
}
