
form {
	width:100%;
	button.btn {
		display:inline-block;
		border-radius:3px;
		font-weight:800;
		padding:18px 20px;
		line-height:1em;
	}
}
label {
	display: block;
	font-family:$standard-font-family;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	color: #CA9C64;
	margin:15px 0 0;
	span.required {
		display: inline-block;
		margin-left:3px;
		color:#BC1F32;
	}
	&:nth-child(1) {
		margin-top:0;
	}
}
input, textarea {
	font-family:$standard-font-family;
	width:100%;
	padding:8px;
	color: #fff;
	font-size:14px;
	margin:3.5px 0;
	background: rgba(51, 51, 51, 0.8);
	border: none;
	&:placeholder {
		color:rgba(#fff, 0.8);
	}
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color:rgba(#fff, 0.4);
}
::-moz-placeholder { /* Firefox 19+ */
  color:rgba(#fff, 0.4);
}
:-ms-input-placeholder { /* IE 10+ */
  color:rgba(#fff, 0.4);
}
:-moz-placeholder { /* Firefox 18- */
  color:rgba(#fff, 0.4);
}
textarea {
	min-height:190px
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
	-webkit-appearance:none;
}

button {
	color:#fff;
	font-family:$standard-font-family;
	text-transform: uppercase;
	margin-top:15px;
	font-size: 13px;
	letter-spacing: 0;
	font-style: normal;
	font-weight: 700;
	background: #CA9C64;
	color: #fff;
	padding: 12.5px 25px;
	width:100%;
}

.dialog-item {
	button {
		margin-top:10px;
	}
}

#validation-msg {
	padding:20px 25px;
	color:#333;
	margin:10px auto;
	text-align:center;
	font-size: 16px;
	color: #262626;
	letter-spacing: 0;
	border: 1px solid #E0D8D8;
	border-radius: 3px;
	line-height: 1;
	background:#ffffcc;
	&.error {
		background: #F7E0E0;
	}
	@media(max-width:900px) { font-size:15px; padding:15px 20px; }
	@media(max-width:600px) { font-size:14px; padding:10px 15px; }
	@media(max-width:450px) { font-size:13px; padding:7.5px 10px; }
}

.select_style {
	overflow: hidden;
	display: inline-block;
	position: relative;
	cursor: pointer;
  background: #F4FBFD;
  border-radius: 3px;
  font-family:$standard-font-family;
  font-size: 14px;
  width:100%;
	position:relative;
	border: 1px solid #D0CFCF;
	margin-bottom:9px;
	font-weight:700;
	color:#6F6F6F;
  @media (max-width:900px) { font-size:15px; line-height: 1em; }
  @media (max-width:600px) { font-size:14px; line-height: 1em; }
  @media (max-width:450px) { font-size:12px; line-height: 1em; }
	&:after {
		content: "\f107";
		font-family: FontAwesome;
		position: absolute;
		color:#909090;
		top:6.5px;
		right:10px;
		pointer-events: none;

	}
	select {
	  -moz-appearance: none;
	  -webkit-appearance: none;
		appearance:none;
		background:none;
		background:transparent;
		border:none;
		cursor:pointer;
		outline:none;
	  width:100%;
		option { padding-right:45px; }
	}
}

.error-input,
div.error-input {
	border:1px $red solid;
}
.error-msg {
	font-size:14px;
	padding:5px 0;
	display: none;
	color:$red;
	text-align:right;
}
