.product-img {
  background: #FFFFFF;
  border: 1px solid #C9C7C7;
  .v-align {
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    height:468px;
  }
}
