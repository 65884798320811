table {
  width:100%;
  border-bottom: 1px solid #E9E9E9;
  border-right: 1px solid #E9E9E9;
  thead {
    tr {
      th {
        background:#013040;
        color:#fff;
        padding:12px 16px;
      }
    }
  }
  tbody {
    tr {
      background: #F7F7F7;
      &:nth-child(odd) {
        background: #fff;
      }
      td {
        padding:12px 16px;
        text-align:center;
        &:nth-child(1) {
          text-align:left;
          font-weight:600;
        }
      }
    }
  }
}
