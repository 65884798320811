.inner-wrap {
  max-width:$container + px;
  width:90%;
  margin:auto;
  .row {
    margin:0;
    display:flex;
    flex-wrap:wrap;
    .col {
      width: 50%;
      margin-bottom: 100px;
      @media(max-width:600px) {

        width:100%;
        &:nth-child(1) {
          margin-bottom:7.5px;
        }
      }
      &:nth-child(1) {
        flex-grow:1;
      }
      a {
        color:rgba(#FFFFFF, 0.65);
      }
      i {
        font-size:16px;
      }
    }
  }
}
