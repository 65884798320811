
$title-font-family:	"relation-two";
$standard-font-family: "proxima-nova";

$container: 1050;

$yellow: #CA9C64;
$gold: $yellow;
$red: #B6051A;
$darkblue: #206788;
$blue: #33A0C3;
$textshadow: 0px 0px 4px rgba(0, 0, 0, 0.67);

$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;
