.letter-spacing {
  display:flex;
  width: 100%;
  div {
    display: inline-block;
    h2 {
      display: table-caption;
    }
  }
}

.home-summary-items {
  margin:50px 0;
  ul {
    display: flex;
    @media(max-width:600px) {
      flex-direction: column;
    }
    li {
      margin-left:60px;
      max-width:133px;
      width:100%;
      &:nth-child(1) {
        margin-left:0;
      }
      @media(max-width:600px) {
        margin-left:0;
        margin-top:60px;
        &:nth-child(1) {
          margin-top:0;
        }
      }
      h4 {
        font-size: 13px;
        color: #FFFFFF;
        letter-spacing: 0;
        font-weight:700;
        border-bottom:2px rgba(#CA9C64, 0.7) solid;
        margin-bottom:5px;
        text-transform:uppercase;
        a {
          color: #FFFFFF;
        }
      }
      .desc {
        font-weight:300;
        font-size: 12px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 14px;
        text-shadow: 0 0 4px rgba(0,0,0,0.67);
      }
    }
  }
}


.footer-contact {
  .row {
    display: flex;
    padding:40px 50px;
    margin:0;
    @media(max-width:600px) { padding:30px 40px; }
    @media(max-width:450px) { padding:20px 30px; }
    @media(max-width:900px) {
      flex-direction: column;
    }
    .col {
      &:nth-child(1) {
        flex-grow:1;
        @media(max-width:900px) {
          display: flex;
          order: 4;
          margin-top:10px;
          span {
            &:nth-child(1) {
              flex-grow: 1;
            }
          }
        }
      }
      span {
        font-size:13px;
        color:#fff;
        display: inline-block;
        &.contact {
          font-style: normal;
          font-weight: 700;
          margin-right:45px;
        }
        &.social-media {
          a {
            color:#fff;
            display: inline-block;
            margin:0 10px;
            i {
              font-size:20px;
            }
          }
        }
        &.opening {
          font-style: normal;
          font-weight: 600;
        }
      }
    }
  }
}