h1 {
  line-height:1.35;
  font-size: 58px;
  color: #CA9C64;
  letter-spacing: 0;
  font-style: normal;
  font-weight: 400;
  text-shadow: $textshadow;
  font-family:$title-font-family;
  @media (max-width:900px) { font-size:46px; }
  @media (max-width:600px) { font-size:34px; }
  @media (max-width:450px) { font-size:28px; }
}

h2 {
  font-size: 58px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 1;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  @media (max-width:900px) { font-size:46px; }
  @media (max-width:600px) { font-size:34px; }
  @media (max-width:450px) { font-size:28px; }
}
