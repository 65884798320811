.cat-selector {
  font-size: 13px;
  color:$gold;
  letter-spacing: 0;
  font-style: normal;
  font-weight: 700;
  margin:25px 0 50px;
  @media(max-width:900px) {
    display: none;
  }
  span {
    margin-right:10px;
    display: inline-block;
    border-bottom:2px $gold solid;
    text-transform: uppercase;
    color:#fff;
    text-shadow: $textshadow;
    position: relative;
    cursor: pointer;
    &.active {
      color:$gold;
    }
    &:active {
      top:1px;
    }
  }
}

.mobile-cat-selector {
  display: none;
  @media(max-width:900px) {
    display: inline-block;
  }
  margin:25px 0;
  max-width:230px;
  &.select_style {
    background:none;
    border:0;
    border-bottom:2px $gold solid;
    padding:3px 0;
    width:auto;
    border-radius: 0;
    &:after {
      color:white;
      position: static;
      display: inline-block;
      margin-left:10px;
      padding-right:10px;
    }
  }
  select {
    padding:0;
    font-size: 13px;
    color:white;
    letter-spacing: 0;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    width:auto;
    option {
    }
  }

}

.menu-items-wrap {
  margin-bottom:100px;
  h2 {
    margin:20px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    line-height:1.25;
    text-shadow: $textshadow;
    @media(max-width:900px) { font-size:21px; }
    @media(max-width:600px) { font-size:18px; }
    @media(max-width:450px) { font-size:16px; }
  }
  .menu-category-wrap {
    ul {
    //  columns: 2;
     // column-gap: 100px;
      max-width:900px;
      display: flex;
      flex-wrap: wrap;
      li {
        display: flex;
        margin-bottom:30px;
        @media(max-width:600px) { margin-bottom: 25px; }
        @media(max-width:450px) { margin-bottom: 20px; }
        width:50%;
        &:nth-child(odd) {
          padding-right:30px;
        }
        &:nth-child(even) {
          padding-left:30px;
        }
        @media(max-width:900px) {
          width:100%;
          &:nth-child(odd) {
            padding-right:0;
          }
          &:nth-child(even) {
            padding-left:0;
          }
        }
        .menu-item-desc {
          flex-grow: 1;
          .menu-item-title {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #FFFFFF;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            line-height:1.25;
            margin-bottom: 3px;
            text-shadow: $textshadow;
            @media(max-width:600px) { font-size:15px; }
            @media(max-width:450px) { font-size:14px; }
          }
          .menu-item-summary {
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            color: #fff;
            letter-spacing: 0;
            line-height:1.25;
            text-shadow: $textshadow;
          }
        }
        .menu-item-pricing {
          font-size: 16px;
          color:$gold;
          padding-left:30px;
          letter-spacing: 0;
          font-style: normal;
          font-weight: 800;
          display: flex;
          text-shadow: $textshadow;
          line-height: 1;
          @media(max-width:600px) { font-size:15px; }
          @media(max-width:450px) { font-size:14px; }
          span.price {
            display: inline-block;
            margin-top:2px;
            &:nth-child(2) {
              margin-left: 12px;
            }

            span.value {
              display:block;
            }
            span.lbl {
              display:block;
              font-size: 10px;
              letter-spacing: 0;
              font-style: normal;
              font-weight: 800;
              text-transform: uppercase;

              margin-top:2px;
            }

          }
        }
      }
    }
    .footnote {
      color:#CCA771;
      letter-spacing: 0;
      line-height: 1.4;
      font-style: normal;
      font-weight: 400;
      font-family:$title-font-family;
      text-shadow: $textshadow;
      margin:20px 0;
      font-size: 22px;
      max-width:560px;
      @media(max-width:900px) { font-size: 22px; }
      @media(max-width:600px) { font-size: 20px; }
      @media(max-width:450px) { font-size: 18px; }
    }
  }
}
