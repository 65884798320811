table.basket {
  margin:35px auto 0;
  font-size:16px;
  width:100%;
  @media(max-width:900px) { font-size:15px; }
  @media(max-width:600px) { font-size:13px; }
  thead {
    tr {
      th {
        font-weight: 800;
        line-height: 1.5em;
        padding:10px 20px;
        text-align:left;
        font-size:16px;
        font-style: normal;
        font-weight: 700;
        @media(max-width:900px) { font-size:15px; padding:7.5px 15px; }
        @media(max-width:600px) { font-size:14px; padding:5px 10px; }
        &:nth-child(1) {
          @media(max-width:450px) { 
            display:none;
          }
        }
        &[align="center"] {
          text-align:center;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        background:#fff;
        padding:10px 20px;
        vertical-align:middle;
        text-align:center;
        border-bottom:1px #E9E9E9 solid;
        color:#494949;
        &:nth-child(1) {
          border-left:1px solid #E9E9E9;
        }
        @media(max-width:900px) { padding:7.5px 15px; }
        @media(max-width:600px) { padding:5px 10px; }
        &.img-wrap {
          @media(max-width:450px) { 
            display:none;
          }
          div {
            width:85px;
            text-align:center;
            margin:0 auto;
          }
          img {
            margin:10px;
            max-height:50px;
          }
        }
        &:nth-child(2) {
          text-align:left;
        }
        &:nth-child(4) {
          color:#D3D3D3;
          font-size: 18px;
          color: #CD1A1E;
          letter-spacing: 0;
          line-height: 1;
          font-weight:800;
          @media(max-width:900px) { font-size:16px; }
          @media(max-width:600px) { font-size:14px; }
        }
        a {
          color:#494949;
          &.title {
            text-transform: capitalize;
            line-height: 1.35;
            display: block;
          }
        }
        input {
          width:42px;
          text-align:center;
        }
        button {
          background:none;
          margin:0;
          padding:0;
          i {
            color:#D3D3D3;
          }
          &:hover {
            i { color:#C91413; }
          }
        }
      }
    }
  }
  tfoot {
    tr {
      td {
        padding:10px 20px;
        background:#E3E3E3;
        border-bottom:1px #E9E9E9 solid;
        @media(max-width:900px) {  }
        @media(max-width:600px) {  }
        &:nth-child(1) {
          @media(max-width:450px) { 
            display:none;
          }
        }
        small {
          font-size: 19px;
          color: #666666;
          font-weight: 500;
          display: inline-block;
          padding:10px 10px 30px 0;
          @media(max-width:950px) { font-size:17px; }
          @media(max-width:700px) { font-size:16px; }
        }
        .delivery-cost {
          font-weight: 800;
          font-size: 23px;
          display: inline-block;
          @media(max-width:900px) { font-size:19px; }
          @media(max-width:700px) { font-size:16px; }
        }
        form {
          display:inline-block;
          width:100%;
          &.promoActions {
            display: block;
            width:100%;
          }
        }
        button {
          background:none;
          border-radius: 0;
          margin: 0;
          line-height: 1em;
          text-transform: uppercase;
          font-size:16px;
          @media(max-width:900px) { font-size:16px; }
          @media(max-width:600px) { font-size:14px; }
          i {
            color:#000;
          }
        }
      }
    }
  }
}



.basket-delivery {
  padding:20px 0 0;
  .row {
    margin:0;
    text-align:right;
    vertical-align: top;
    #delivery_dropdowns_wrap {
      display: inline-block;
    }
    .lbl {
      font-size: 18px;
      color: #353535;
      letter-spacing: 0;
      line-height: 1;
      vertical-align: top;
      display: inline-block;
      font-weight:700;
      margin:6.5px 10px;
      @media(max-width:900px) { font-size:17px; }
      @media(max-width:600px) { font-size:16px; }
      @media(max-width:450px) { font-size:15px; }
     }
     .delivery-cost {
       display:inline-block;
       margin:5px 0;
       font-size: 18px;
       padding-right:10px;
       color:#CD1A1E;
       font-weight:800;
       @media(max-width:900px) { font-size:17px; }
       @media(max-width:600px) { font-size:16px; }
       @media(max-width:450px) { font-size:15px; }
     }
     
     .select_style {
      font-size: 16px;
      @media(max-width:900px) { font-size:15px; }
      @media(max-width:600px) { font-size:14px; }
      @media(max-width:450px) { font-size:13px; }
      color: #777777;
      background:#fff;
      border: 1px solid #CFD1D2;
      border-radius: 3px;
      margin:0 0 5px;
      padding:5px 10px;
      width:270px;
      display: block;
      &.delivery-options-dropdown {
        display: none;
      }
    }
    form {
      display: inline-block;
      vertical-align: top;
      width: auto;
    }
    button {
      padding:3.5px 7px;
      margin:3px 0 0;
      line-height: 1;
    }
     
  }
}

.basket-subtotal {
  padding:10px 0 0;
  .row {
    margin:0;
    text-align:right;
    .lbl {
      display:inline-block;
      font-size: 16px;
      color: #353535;
      letter-spacing: 0;
      line-height: 1;
      font-style: normal;
      font-weight: 800;
      @media(max-width:900px) { font-size:15px; }
      @media(max-width:600px) { font-size:14px; }
      @media(max-width:450px) { font-size:13px; }
    }
    .total {
      display:inline-block;
      font-size: 18px;
      color: #CD1A1E;
      letter-spacing: 0;
      line-height: 1;
      font-style: normal;
      font-weight: 700;
      margin:0 5px 0 10px;
      @media(max-width:900px) { font-size:17px; }
      @media(max-width:600px) { font-size:16px; }
      @media(max-width:450px) { font-size:14px; }
    }

    form {
      display: inline-block;
      vertical-align: top;
      width: auto;
    }
    button {
      padding:3.5px 7px;
      margin:3px 0 0;
      line-height: 1;
    }
  }
}

.basket-total {
  padding:20px 0;
  .row {
    margin:0;
    text-align:right;
    .lbl {
      display:inline-block;
      font-size: 26px;
      color: #353535;
      letter-spacing: 0;
      line-height: 1;
      font-style: normal;
      font-weight: 800;
      @media(max-width:900px) { font-size:22px; }
      @media(max-width:600px) { font-size:20px; }
      @media(max-width:450px) { font-size:18px; }
    }
    .total {
      display:inline-block;
      font-size: 30px;
      color: #CD1A1E;
      letter-spacing: 0;
      line-height: 1;
      font-style: normal;
      font-weight: 700;
      margin:0 5px 0 10px;
      @media(max-width:900px) { font-size:28px; }
      @media(max-width:600px) { font-size:26px; }
      @media(max-width:450px) { font-size:24px; }
    }
  }
}


.chk-btn {
  margin:35px auto;
  text-align: center;
  display: block;
}

.hide_from_mobile {
  @media(max-width:600px) {
    display: none;
  }
}


.voucher-frm-wrap {
  margin:50px auto 0;
  background: #F0F3F5;
  border: 1px solid #E0E0E0;
  padding: 20px 15px;
  margin-bottom: 25px;
  label {
    font-size: 16px;
    color: #4F5458;
    letter-spacing: 0;
    line-height: 1;
    margin: 0 0 10px;
    display: block;
    font-weight: 600;
  }
  input {
    min-width: 40px;
    height: 40px;
    text-align: center;
    margin: 0;
    width:auto;
    vertical-align: top;
    border-radius:0;
    &[name="voucher_code_txt"] {
    
    }
    &.btn {
      border:1px solid #59AAC1;
      border-left:0;
      border-radius:0;
    }
  }
}