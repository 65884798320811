html {
  box-sizing: border-box;
}

body {
  background-color: #fff;
  font:13px/1.5em $standard-font-family;
  font-weight:400;
  font-style:normal;
  color:#fff;
  line-height:1.85;
  border-top:20px #fff solid;
  border-left:20px #fff solid;
  border-right:20px #fff solid;
  position: relative;
  background-image:url('../imgs/bck.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  @media(max-width:900px) {
    border-top:15px #fff solid;
    border-left:15px #fff solid;
    border-right:15px #fff solid;
  }
  @media(max-width:600px) {
    border-top:10px #fff solid;
    border-left:10px #fff solid;
    border-right:10px #fff solid;
  }
  &:after {
    width:100%;
    height:20px;
    content: " ";
    background:#fff;
    position: fixed;
    bottom:0;
    left:0;
    z-index: 1000;
    @media(max-width:900px) { height:15px; }
    @media(max-width:600px) { height:10px; }
  }
  &:after {
    content: " ";
    position: absolute;
    left:0;
    top:0;
    min-height:calc(100vh - 20px);
    width:100%;
    background-color:#000000;
    opacity: 0.3;
    z-index: 200;
    height: 100%;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}

strong {
  font-weight:700;
}


fieldset {
  border:0;
  margin:0;
  padding:0;
}

img {
  max-width:100%;
}

.center { text-align:center; }
.clear { clear:both; }


#page-wrap {
  position: relative;
}

#page-wrap-inner {
  position: relative;
  right: 0;
  z-index: 100;
  width: 100%;
  min-height: calc(100vh - 20px);
  z-index: 300;
  overflow: hidden;
  a {
    position: relative;
    &:active {
      top:1px;
    }
  }
  &:after {
    content: " ";
    width:100%;
    position: fixed;
    left:0;
    bottom:0;
    background:#fff;
    height:20px;
    z-index: 6000;
    @media(max-width:900px) { height:15px; }
    @media(max-width:600px) { height:10px; }
  }
  &:before {
    content: " ";
    width:634px;
    right:-60px;
    top:-170px;
    background:#fff;
    height:606px;
    z-index: 5500;
    background: url(../imgs/logo_overlay.png);
    position: absolute;
    display: block;
    opacity: 0.1;
    pointer-events: none;

  }
  #page-wrap-frame {
    display: flex;
    flex-direction: column;
    min-height:calc(100vh - 20px);
    .homepage-wrap {
      min-height: calc(100vh - 300px);
      @media(max-width:600px) {
        min-height: calc(100vh - 300px);
      }
    }

  }
}

#productFeatureLoader  { display:none; }


#loader {
  display:block;
  margin:35px auto;
}

#zero {
  display:none;
  text-align:center;
  color:#fff;
  padding:35px 0;
}

.default-pointer {
  cursor: default;
}

.hide-from-mobile {
  display: block;
  @media(max-width:900px) {
    display:none;
  }
}

.show-only-on-mobile {
  display: none;
  @media(max-width:900px) {
    clear:both;
    display:inline-block;
  }
}

.loader {
    display: none;
}

.content-wrap {
  p { margin:0 0 1.5em; }
}


.mobile-submenu {
  display: none;
  @media(max-width:825px) {
    display: block;
  }
  .select_style {
    padding:7.5px;
    margin-bottom:15px;
  }
}

.subpage-wrap {
  padding:50px 0;
  @media(max-width:900px) { padding:40px 0; }
  @media(max-width:600px) { padding:30px 0; }
  @media(max-width:450px) { padding:25px 0; }
  h1 {
    margin:10px 0;
  }
}

.hr {
  border-top:1px #E9E9E9 solid;
  padding-top:50px;
  margin-top:50px;
  &.featured-btns-hr {
    padding-top:0;
  }
}

.mobile-submenu {
  display: none;
  @media(max-width:825px) {
    display: block;
  }
  .select_style {
    padding:7.5px;
    margin-bottom:15px;
  }
}
