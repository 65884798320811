.inner-wrap {
  .row {
    .col {
      h1{
        line-height: .35;
        font-size: 53px;
        @media(max-width:600px){
          font-size: 28px;
          margin-top: 30px;
        }
      }
      h2{
        margin-bottom: 15px;
        font-size: 50px;
        @media(max-width:600px){
          font-size: 28px;
        }
      }
      p{
        margin-bottom: 20px;
        line-height: 20px;
        &:nth-child(3){
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
        }
      }
      &:nth-child(1){
        justify-content: center;
        align-self: center;
      }
      &:nth-child(2){
        text-align: right;
        img{
          border: 10px solid #fff;
          @media(max-width:940px){
            width: 100%;
          }

        }
      }

    }
  }
}
