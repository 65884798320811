.map {
  height:250px;
  margin: 20px 0px;
  width:100%;
  display: block;
  .infoMarker {
    text-align:left;
    color:#333;
  }
}
